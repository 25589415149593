{
    "timestamp": "2022-08-15T03:19:35.3709271Z",
    "forecast": {
        "spotId": "Bali_Canggu_Batu_Bolong",
        "hourlyForecasts": [
            {
                "dateTime": "2022-08-14T15:00:00Z",
                "wavesSizeMin": 2.3950131999999997,
                "wavesSizeMax": 3.3792652,
                "primarySwellPeriod": 6,
                "primarySwellDirection": 130,
                "secondarySwellPeriod": 11,
                "secondarySwellDirection": 206,
                "longestSwellPeriod": 19,
                "longestSwellDirection": 227,
                "windDirection": 103.45758,
                "windSpeed": 10.21070026097,
                "tideHeight": 2.39,
                "tideHeightPercent": 0.81,
                "tideType": 1
            },
            {
                "dateTime": "2022-08-14T16:00:00Z",
                "wavesSizeMin": 2.3838583439999996,
                "wavesSizeMax": 3.2941820826666666,
                "primarySwellPeriod": 6,
                "primarySwellDirection": 129,
                "secondarySwellPeriod": 10,
                "secondarySwellDirection": 206,
                "longestSwellPeriod": 19,
                "longestSwellDirection": 225,
                "windDirection": 100.43876666666667,
                "windSpeed": 9.942953583236667,
                "tideHeight": 2.28,
                "tideHeightPercent": 0.78,
                "tideType": 1
            },
            {
                "dateTime": "2022-08-14T17:00:00Z",
                "wavesSizeMin": 2.372703488,
                "wavesSizeMax": 3.2090989653333333,
                "primarySwellPeriod": 6,
                "primarySwellDirection": 128,
                "secondarySwellPeriod": 10,
                "secondarySwellDirection": 206,
                "longestSwellPeriod": 19,
                "longestSwellDirection": 224,
                "windDirection": 97.41995333333332,
                "windSpeed": 9.675206905503334,
                "tideHeight": 1.93,
                "tideHeightPercent": 0.68,
                "tideType": 1
            },
            {
                "dateTime": "2022-08-14T18:00:00Z",
                "wavesSizeMin": 2.361548632,
                "wavesSizeMax": 3.124015848,
                "primarySwellPeriod": 6,
                "primarySwellDirection": 127,
                "secondarySwellPeriod": 10,
                "secondarySwellDirection": 206,
                "longestSwellPeriod": 19,
                "longestSwellDirection": 222,
                "windDirection": 94.40114,
                "windSpeed": 9.40746022777,
                "tideHeight": 1.44,
                "tideHeightPercent": 0.53,
                "tideType": 1
            },
            {
                "dateTime": "2022-08-14T19:00:00Z",
                "wavesSizeMin": 2.2410324426666666,
                "wavesSizeMax": 3.082677264,
                "primarySwellPeriod": 6,
                "primarySwellDirection": 126,
                "secondarySwellPeriod": 10,
                "secondarySwellDirection": 206,
                "longestSwellPeriod": 18,
                "longestSwellDirection": 222,
                "windDirection": 94.7931,
                "windSpeed": 9.48637494332,
                "tideHeight": 0.97,
                "tideHeightPercent": 0.39,
                "tideType": 1
            },
            {
                "dateTime": "2022-08-14T20:00:00Z",
                "wavesSizeMin": 2.1205162533333333,
                "wavesSizeMax": 3.04133868,
                "primarySwellPeriod": 6,
                "primarySwellDirection": 126,
                "secondarySwellPeriod": 10,
                "secondarySwellDirection": 206,
                "longestSwellPeriod": 18,
                "longestSwellDirection": 222,
                "windDirection": 95.18506000000001,
                "windSpeed": 9.56528965887,
                "tideHeight": 0.63,
                "tideHeightPercent": 0.3,
                "tideType": 1
            },
            {
                "dateTime": "2022-08-14T21:00:00Z",
                "wavesSizeMin": 2.000000064,
                "wavesSizeMax": 3.000000096,
                "primarySwellPeriod": 6,
                "primarySwellDirection": 125,
                "secondarySwellPeriod": 10,
                "secondarySwellDirection": 206,
                "longestSwellPeriod": 18,
                "longestSwellDirection": 222,
                "windDirection": 95.57702,
                "windSpeed": 9.64420437442,
                "tideHeight": 0.52,
                "tideHeightPercent": 0.26,
                "tideType": 1
            },
            {
                "dateTime": "2022-08-14T22:00:00Z",
                "wavesSizeMin": 2.430300456888889,
                "wavesSizeMax": 3.4431322186666664,
                "primarySwellPeriod": 6,
                "primarySwellDirection": 125,
                "secondarySwellPeriod": 10,
                "secondarySwellDirection": 206,
                "longestSwellPeriod": 18,
                "longestSwellDirection": 222,
                "windDirection": 99.44856333333334,
                "windSpeed": 10.302896118863332,
                "tideHeight": 0.66,
                "tideHeightPercent": 0.3,
                "tideType": 1
            },
            {
                "dateTime": "2022-08-14T23:00:00Z",
                "wavesSizeMin": 2.860600849777778,
                "wavesSizeMax": 3.886264341333333,
                "primarySwellPeriod": 6,
                "primarySwellDirection": 125,
                "secondarySwellPeriod": 10,
                "secondarySwellDirection": 206,
                "longestSwellPeriod": 18,
                "longestSwellDirection": 222,
                "windDirection": 103.32010666666666,
                "windSpeed": 10.961587863306667,
                "tideHeight": 1.02,
                "tideHeightPercent": 0.41,
                "tideType": 1
            },
            {
                "dateTime": "2022-08-15T00:00:00Z",
                "wavesSizeMin": 3.290901242666667,
                "wavesSizeMax": 4.329396463999999,
                "primarySwellPeriod": 6,
                "primarySwellDirection": 125,
                "secondarySwellPeriod": 10,
                "secondarySwellDirection": 207,
                "longestSwellPeriod": 18,
                "longestSwellDirection": 222,
                "windDirection": 107.19165,
                "windSpeed": 11.62027960775,
                "tideHeight": 1.5,
                "tideHeightPercent": 0.55,
                "tideType": 1
            },
            {
                "dateTime": "2022-08-15T01:00:00Z",
                "wavesSizeMin": 3.843394698666667,
                "wavesSizeMax": 4.884733314666666,
                "primarySwellPeriod": 6,
                "primarySwellDirection": 124,
                "secondarySwellPeriod": 6,
                "secondarySwellDirection": 138,
                "longestSwellPeriod": 17,
                "longestSwellDirection": 222,
                "windDirection": 109.22438,
                "windSpeed": 11.974547195306666,
                "tideHeight": 1.97,
                "tideHeightPercent": 0.69,
                "tideType": 1
            },
            {
                "dateTime": "2022-08-15T02:00:00Z",
                "wavesSizeMin": 4.395888154666667,
                "wavesSizeMax": 5.440070165333333,
                "primarySwellPeriod": 6,
                "primarySwellDirection": 124,
                "secondarySwellPeriod": 3,
                "secondarySwellDirection": 69,
                "longestSwellPeriod": 17,
                "longestSwellDirection": 221,
                "windDirection": 111.25711,
                "windSpeed": 12.328814782863333,
                "tideHeight": 2.29,
                "tideHeightPercent": 0.78,
                "tideType": 1
            },
            {
                "dateTime": "2022-08-15T03:00:00Z",
                "wavesSizeMin": 4.948381610666667,
                "wavesSizeMax": 5.995407016,
                "primarySwellPeriod": 6,
                "primarySwellDirection": 123,
                "secondarySwellPeriod": 0,
                "secondarySwellDirection": 0,
                "longestSwellPeriod": 17,
                "longestSwellDirection": 221,
                "windDirection": 113.28984,
                "windSpeed": 12.68308237042,
                "tideHeight": 2.39,
                "tideHeightPercent": 0.81,
                "tideType": 1
            },
            {
                "dateTime": "2022-08-15T04:00:00Z",
                "wavesSizeMin": 5.343978007111112,
                "wavesSizeMax": 6.435695743999999,
                "primarySwellPeriod": 6,
                "primarySwellDirection": 123,
                "secondarySwellPeriod": 0,
                "secondarySwellDirection": 0,
                "longestSwellPeriod": 17,
                "longestSwellDirection": 221,
                "windDirection": 114.75487,
                "windSpeed": 12.49562549873,
                "tideHeight": 2.22,
                "tideHeightPercent": 0.76,
                "tideType": 1
            },
            {
                "dateTime": "2022-08-15T05:00:00Z",
                "wavesSizeMin": 5.739574403555555,
                "wavesSizeMax": 6.875984472,
                "primarySwellPeriod": 6,
                "primarySwellDirection": 123,
                "secondarySwellPeriod": 0,
                "secondarySwellDirection": 0,
                "longestSwellPeriod": 17,
                "longestSwellDirection": 221,
                "windDirection": 116.2199,
                "windSpeed": 12.308168627039999,
                "tideHeight": 1.81,
                "tideHeightPercent": 0.64,
                "tideType": 1
            },
            {
                "dateTime": "2022-08-15T06:00:00Z",
                "wavesSizeMin": 6.1351708,
                "wavesSizeMax": 7.3162731999999995,
                "primarySwellPeriod": 6,
                "primarySwellDirection": 123,
                "secondarySwellPeriod": 0,
                "secondarySwellDirection": 0,
                "longestSwellPeriod": 17,
                "longestSwellDirection": 221,
                "windDirection": 117.68493,
                "windSpeed": 12.12071175535,
                "tideHeight": 1.27,
                "tideHeightPercent": 0.48,
                "tideType": 1
            },
            {
                "dateTime": "2022-08-15T07:00:00Z",
                "wavesSizeMin": 6.4085741333333335,
                "wavesSizeMax": 7.644357199999999,
                "primarySwellPeriod": 6,
                "primarySwellDirection": 124,
                "secondarySwellPeriod": 3,
                "secondarySwellDirection": 67,
                "longestSwellPeriod": 17,
                "longestSwellDirection": 221,
                "windDirection": 117.78027,
                "windSpeed": 11.435985084223333,
                "tideHeight": 0.72,
                "tideHeightPercent": 0.32,
                "tideType": 1
            },
            {
                "dateTime": "2022-08-15T08:00:00Z",
                "wavesSizeMin": 6.681977466666667,
                "wavesSizeMax": 7.9724412,
                "primarySwellPeriod": 6,
                "primarySwellDirection": 124,
                "secondarySwellPeriod": 6,
                "secondarySwellDirection": 135,
                "longestSwellPeriod": 17,
                "longestSwellDirection": 221,
                "windDirection": 117.87561,
                "windSpeed": 10.751258413096666,
                "tideHeight": 0.29,
                "tideHeightPercent": 0.2,
                "tideType": 1
            },
            {
                "dateTime": "2022-08-15T09:00:00Z",
                "wavesSizeMin": 6.9553808,
                "wavesSizeMax": 8.3005252,
                "primarySwellPeriod": 6,
                "primarySwellDirection": 125,
                "secondarySwellPeriod": 9,
                "secondarySwellDirection": 203,
                "longestSwellPeriod": 17,
                "longestSwellDirection": 221,
                "windDirection": 117.97095,
                "windSpeed": 10.06653174197,
                "tideHeight": 0.09,
                "tideHeightPercent": 0.14,
                "tideType": 1
            },
            {
                "dateTime": "2022-08-15T10:00:00Z",
                "wavesSizeMin": 7.1850396000000005,
                "wavesSizeMax": 8.573928533333332,
                "primarySwellPeriod": 6,
                "primarySwellDirection": 125,
                "secondarySwellPeriod": 6,
                "secondarySwellDirection": 135,
                "longestSwellPeriod": 17,
                "longestSwellDirection": 220,
                "windDirection": 115.80182,
                "windSpeed": 10.851222452363332,
                "tideHeight": 0.16,
                "tideHeightPercent": 0.16,
                "tideType": 1
            },
            {
                "dateTime": "2022-08-15T11:00:00Z",
                "wavesSizeMin": 7.4146984,
                "wavesSizeMax": 8.847331866666666,
                "primarySwellPeriod": 6,
                "primarySwellDirection": 124,
                "secondarySwellPeriod": 3,
                "secondarySwellDirection": 67,
                "longestSwellPeriod": 17,
                "longestSwellDirection": 220,
                "windDirection": 113.63269,
                "windSpeed": 11.635913162756667,
                "tideHeight": 0.48,
                "tideHeightPercent": 0.25,
                "tideType": 1
            },
            {
                "dateTime": "2022-08-15T12:00:00Z",
                "wavesSizeMin": 7.6443572,
                "wavesSizeMax": 9.120735199999999,
                "primarySwellPeriod": 6,
                "primarySwellDirection": 124,
                "secondarySwellPeriod": 0,
                "secondarySwellDirection": 0,
                "longestSwellPeriod": 17,
                "longestSwellDirection": 220,
                "windDirection": 111.46356,
                "windSpeed": 12.42060387315,
                "tideHeight": 0.98,
                "tideHeightPercent": 0.4,
                "tideType": 1
            },
            {
                "dateTime": "2022-08-15T13:00:00Z",
                "wavesSizeMin": 7.786526933333334,
                "wavesSizeMax": 9.295713333333332,
                "primarySwellPeriod": 6,
                "primarySwellDirection": 124,
                "secondarySwellPeriod": 0,
                "secondarySwellDirection": 0,
                "longestSwellPeriod": 16,
                "longestSwellDirection": 220,
                "windDirection": 113.31501333333334,
                "windSpeed": 12.060162777226667,
                "tideHeight": 1.53,
                "tideHeightPercent": 0.56,
                "tideType": 1
            },
            {
                "dateTime": "2022-08-15T14:00:00Z",
                "wavesSizeMin": 7.928696666666666,
                "wavesSizeMax": 9.470691466666667,
                "primarySwellPeriod": 6,
                "primarySwellDirection": 123,
                "secondarySwellPeriod": 0,
                "secondarySwellDirection": 0,
                "longestSwellPeriod": 16,
                "longestSwellDirection": 220,
                "windDirection": 115.16646666666666,
                "windSpeed": 11.699721681303334,
                "tideHeight": 2,
                "tideHeightPercent": 0.7,
                "tideType": 1
            },
            {
                "dateTime": "2022-08-15T15:00:00Z",
                "wavesSizeMin": 8.0708664,
                "wavesSizeMax": 9.6456696,
                "primarySwellPeriod": 6,
                "primarySwellDirection": 122,
                "secondarySwellPeriod": 0,
                "secondarySwellDirection": 0,
                "longestSwellPeriod": 16,
                "longestSwellDirection": 220,
                "windDirection": 117.01792,
                "windSpeed": 11.339280585380001,
                "tideHeight": 2.29,
                "tideHeightPercent": 0.78,
                "tideType": 1
            },
            {
                "dateTime": "2022-08-15T16:00:00Z",
                "wavesSizeMin": 8.1364832,
                "wavesSizeMax": 9.722222533333333,
                "primarySwellPeriod": 6,
                "primarySwellDirection": 122,
                "secondarySwellPeriod": 0,
                "secondarySwellDirection": 0,
                "longestSwellPeriod": 16,
                "longestSwellDirection": 220,
                "windDirection": 110.70000666666667,
                "windSpeed": 9.925687558233335,
                "tideHeight": 2.33,
                "tideHeightPercent": 0.79,
                "tideType": 1
            },
            {
                "dateTime": "2022-08-15T17:00:00Z",
                "wavesSizeMin": 8.2021,
                "wavesSizeMax": 9.798775466666665,
                "primarySwellPeriod": 6,
                "primarySwellDirection": 121,
                "secondarySwellPeriod": 0,
                "secondarySwellDirection": 0,
                "longestSwellPeriod": 16,
                "longestSwellDirection": 220,
                "windDirection": 104.38209333333333,
                "windSpeed": 8.512094531086667,
                "tideHeight": 2.12,
                "tideHeightPercent": 0.73,
                "tideType": 1
            },
            {
                "dateTime": "2022-08-15T18:00:00Z",
                "wavesSizeMin": 8.2677168,
                "wavesSizeMax": 9.875328399999999,
                "primarySwellPeriod": 6,
                "primarySwellDirection": 121,
                "secondarySwellPeriod": 0,
                "secondarySwellDirection": 0,
                "longestSwellPeriod": 16,
                "longestSwellDirection": 220,
                "windDirection": 98.06418,
                "windSpeed": 7.098501503940001,
                "tideHeight": 1.73,
                "tideHeightPercent": 0.62,
                "tideType": 1
            },
            {
                "dateTime": "2022-08-15T19:00:00Z",
                "wavesSizeMin": 8.256780666666668,
                "wavesSizeMax": 9.853456133333333,
                "primarySwellPeriod": 6,
                "primarySwellDirection": 120,
                "secondarySwellPeriod": 0,
                "secondarySwellDirection": 0,
                "longestSwellPeriod": 16,
                "longestSwellDirection": 220,
                "windDirection": 83.52647999999999,
                "windSpeed": 6.656861674356667,
                "tideHeight": 1.27,
                "tideHeightPercent": 0.48,
                "tideType": 1
            },
            {
                "dateTime": "2022-08-15T20:00:00Z",
                "wavesSizeMin": 8.245844533333333,
                "wavesSizeMax": 9.831583866666666,
                "primarySwellPeriod": 6,
                "primarySwellDirection": 119,
                "secondarySwellPeriod": 0,
                "secondarySwellDirection": 0,
                "longestSwellPeriod": 16,
                "longestSwellDirection": 220,
                "windDirection": 68.98877999999999,
                "windSpeed": 6.215221844773334,
                "tideHeight": 0.87,
                "tideHeightPercent": 0.37,
                "tideType": 1
            },
            {
                "dateTime": "2022-08-15T21:00:00Z",
                "wavesSizeMin": 8.2349084,
                "wavesSizeMax": 9.8097116,
                "primarySwellPeriod": 6,
                "primarySwellDirection": 119,
                "secondarySwellPeriod": 0,
                "secondarySwellDirection": 0,
                "longestSwellPeriod": 16,
                "longestSwellDirection": 220,
                "windDirection": 54.45108,
                "windSpeed": 5.77358201519,
                "tideHeight": 0.63,
                "tideHeightPercent": 0.3,
                "tideType": 1
            },
            {
                "dateTime": "2022-08-15T22:00:00Z",
                "wavesSizeMin": 8.048994133333334,
                "wavesSizeMax": 9.601925066666666,
                "primarySwellPeriod": 6,
                "primarySwellDirection": 119,
                "secondarySwellPeriod": 0,
                "secondarySwellDirection": 0,
                "longestSwellPeriod": 16,
                "longestSwellDirection": 220,
                "windDirection": 66.45766666666667,
                "windSpeed": 5.857829706043333,
                "tideHeight": 0.61,
                "tideHeightPercent": 0.29,
                "tideType": 1
            },
            {
                "dateTime": "2022-08-15T23:00:00Z",
                "wavesSizeMin": 7.863079866666666,
                "wavesSizeMax": 9.394138533333333,
                "primarySwellPeriod": 6,
                "primarySwellDirection": 119,
                "secondarySwellPeriod": 0,
                "secondarySwellDirection": 0,
                "longestSwellPeriod": 16,
                "longestSwellDirection": 220,
                "windDirection": 78.46425333333333,
                "windSpeed": 5.942077396896667,
                "tideHeight": 0.82,
                "tideHeightPercent": 0.35,
                "tideType": 1
            },
            {
                "dateTime": "2022-08-16T00:00:00Z",
                "wavesSizeMin": 7.6771655999999995,
                "wavesSizeMax": 9.186352,
                "primarySwellPeriod": 6,
                "primarySwellDirection": 119,
                "secondarySwellPeriod": 0,
                "secondarySwellDirection": 0,
                "longestSwellPeriod": 16,
                "longestSwellDirection": 220,
                "windDirection": 90.47084,
                "windSpeed": 6.02632508775,
                "tideHeight": 1.2,
                "tideHeightPercent": 0.46,
                "tideType": 1
            },
            {
                "dateTime": "2022-08-16T01:00:00Z",
                "wavesSizeMin": 7.600612666666667,
                "wavesSizeMax": 9.098862933333333,
                "primarySwellPeriod": 6,
                "primarySwellDirection": 120,
                "secondarySwellPeriod": 0,
                "secondarySwellDirection": 0,
                "longestSwellPeriod": 16,
                "longestSwellDirection": 220,
                "windDirection": 104.69418,
                "windSpeed": 7.09691942993,
                "tideHeight": 1.64,
                "tideHeightPercent": 0.59,
                "tideType": 1
            },
            {
                "dateTime": "2022-08-16T02:00:00Z",
                "wavesSizeMin": 7.524059733333333,
                "wavesSizeMax": 9.011373866666666,
                "primarySwellPeriod": 6,
                "primarySwellDirection": 120,
                "secondarySwellPeriod": 0,
                "secondarySwellDirection": 0,
                "longestSwellPeriod": 16,
                "longestSwellDirection": 220,
                "windDirection": 118.91752,
                "windSpeed": 8.16751377211,
                "tideHeight": 2.01,
                "tideHeightPercent": 0.7,
                "tideType": 1
            },
            {
                "dateTime": "2022-08-16T03:00:00Z",
                "wavesSizeMin": 7.4475068,
                "wavesSizeMax": 8.9238848,
                "primarySwellPeriod": 6,
                "primarySwellDirection": 121,
                "secondarySwellPeriod": 0,
                "secondarySwellDirection": 0,
                "longestSwellPeriod": 16,
                "longestSwellDirection": 220,
                "windDirection": 133.14086,
                "windSpeed": 9.23810811429,
                "tideHeight": 2.22,
                "tideHeightPercent": 0.76,
                "tideType": 1
            },
            {
                "dateTime": "2022-08-16T04:00:00Z",
                "wavesSizeMin": 7.38189,
                "wavesSizeMax": 8.836395733333333,
                "primarySwellPeriod": 6,
                "primarySwellDirection": 121,
                "secondarySwellPeriod": 0,
                "secondarySwellDirection": 0,
                "longestSwellPeriod": 15,
                "longestSwellDirection": 220,
                "windDirection": 131.28423666666666,
                "windSpeed": 10.283353275176667,
                "tideHeight": 2.21,
                "tideHeightPercent": 0.76,
                "tideType": 1
            },
            {
                "dateTime": "2022-08-16T05:00:00Z",
                "wavesSizeMin": 7.3162731999999995,
                "wavesSizeMax": 8.748906666666667,
                "primarySwellPeriod": 6,
                "primarySwellDirection": 121,
                "secondarySwellPeriod": 0,
                "secondarySwellDirection": 0,
                "longestSwellPeriod": 15,
                "longestSwellDirection": 220,
                "windDirection": 129.42761333333334,
                "windSpeed": 11.328598436063334,
                "tideHeight": 1.96,
                "tideHeightPercent": 0.68,
                "tideType": 1
            },
            {
                "dateTime": "2022-08-16T06:00:00Z",
                "wavesSizeMin": 7.2506564,
                "wavesSizeMax": 8.6614176,
                "primarySwellPeriod": 6,
                "primarySwellDirection": 121,
                "secondarySwellPeriod": 0,
                "secondarySwellDirection": 0,
                "longestSwellPeriod": 15,
                "longestSwellDirection": 220,
                "windDirection": 127.57099,
                "windSpeed": 12.373843596950001,
                "tideHeight": 1.54,
                "tideHeightPercent": 0.56,
                "tideType": 1
            },
            {
                "dateTime": "2022-08-16T07:00:00Z",
                "wavesSizeMin": 7.174103466666667,
                "wavesSizeMax": 8.5629924,
                "primarySwellPeriod": 6,
                "primarySwellDirection": 120,
                "secondarySwellPeriod": 0,
                "secondarySwellDirection": 0,
                "longestSwellPeriod": 15,
                "longestSwellDirection": 220,
                "windDirection": 124.98388333333332,
                "windSpeed": 12.347229116420001,
                "tideHeight": 1.04,
                "tideHeightPercent": 0.42,
                "tideType": 1
            },
            {
                "dateTime": "2022-08-16T08:00:00Z",
                "wavesSizeMin": 7.097550533333333,
                "wavesSizeMax": 8.4645672,
                "primarySwellPeriod": 6,
                "primarySwellDirection": 120,
                "secondarySwellPeriod": 0,
                "secondarySwellDirection": 0,
                "longestSwellPeriod": 15,
                "longestSwellDirection": 220,
                "windDirection": 122.39677666666667,
                "windSpeed": 12.320614635890001,
                "tideHeight": 0.59,
                "tideHeightPercent": 0.28,
                "tideType": 1
            },
            {
                "dateTime": "2022-08-16T09:00:00Z",
                "wavesSizeMin": 7.0209976,
                "wavesSizeMax": 8.366142,
                "primarySwellPeriod": 7,
                "primarySwellDirection": 120,
                "secondarySwellPeriod": 0,
                "secondarySwellDirection": 0,
                "longestSwellPeriod": 15,
                "longestSwellDirection": 220,
                "windDirection": 119.80967,
                "windSpeed": 12.294000155360001,
                "tideHeight": 0.3,
                "tideHeightPercent": 0.2,
                "tideType": 1
            },
            {
                "dateTime": "2022-08-16T10:00:00Z",
                "wavesSizeMin": 6.9225724,
                "wavesSizeMax": 8.256780666666668,
                "primarySwellPeriod": 6,
                "primarySwellDirection": 119,
                "secondarySwellPeriod": 0,
                "secondarySwellDirection": 0,
                "longestSwellPeriod": 15,
                "longestSwellDirection": 220,
                "windDirection": 120.15121666666667,
                "windSpeed": 12.457329948433335,
                "tideHeight": 0.23,
                "tideHeightPercent": 0.18,
                "tideType": 1
            },
            {
                "dateTime": "2022-08-16T11:00:00Z",
                "wavesSizeMin": 6.8241472,
                "wavesSizeMax": 8.147419333333334,
                "primarySwellPeriod": 6,
                "primarySwellDirection": 119,
                "secondarySwellPeriod": 0,
                "secondarySwellDirection": 0,
                "longestSwellPeriod": 15,
                "longestSwellDirection": 220,
                "windDirection": 120.49276333333333,
                "windSpeed": 12.620659741506667,
                "tideHeight": 0.4,
                "tideHeightPercent": 0.23,
                "tideType": 1
            },
            {
                "dateTime": "2022-08-16T12:00:00Z",
                "wavesSizeMin": 6.725721999999999,
                "wavesSizeMax": 8.038058000000001,
                "primarySwellPeriod": 6,
                "primarySwellDirection": 119,
                "secondarySwellPeriod": 0,
                "secondarySwellDirection": 0,
                "longestSwellPeriod": 15,
                "longestSwellDirection": 220,
                "windDirection": 120.83431,
                "windSpeed": 12.783989534580002,
                "tideHeight": 0.78,
                "tideHeightPercent": 0.34,
                "tideType": 1
            },
            {
                "dateTime": "2022-08-16T13:00:00Z",
                "wavesSizeMin": 6.5944883999999995,
                "wavesSizeMax": 7.884952133333334,
                "primarySwellPeriod": 6,
                "primarySwellDirection": 119,
                "secondarySwellPeriod": 0,
                "secondarySwellDirection": 0,
                "longestSwellPeriod": 15,
                "longestSwellDirection": 219,
                "windDirection": 119.25969,
                "windSpeed": 12.852461481750002,
                "tideHeight": 1.25,
                "tideHeightPercent": 0.48,
                "tideType": 1
            },
            {
                "dateTime": "2022-08-16T14:00:00Z",
                "wavesSizeMin": 6.4632548,
                "wavesSizeMax": 7.731846266666667,
                "primarySwellPeriod": 6,
                "primarySwellDirection": 119,
                "secondarySwellPeriod": 0,
                "secondarySwellDirection": 0,
                "longestSwellPeriod": 15,
                "longestSwellDirection": 219,
                "windDirection": 117.68507,
                "windSpeed": 12.920933428920002,
                "tideHeight": 1.73,
                "tideHeightPercent": 0.62,
                "tideType": 1
            },
            {
                "dateTime": "2022-08-16T15:00:00Z",
                "wavesSizeMin": 6.3320212,
                "wavesSizeMax": 7.5787404,
                "primarySwellPeriod": 6,
                "primarySwellDirection": 118,
                "secondarySwellPeriod": 0,
                "secondarySwellDirection": 0,
                "longestSwellPeriod": 15,
                "longestSwellDirection": 219,
                "windDirection": 116.11045,
                "windSpeed": 12.989405376090001,
                "tideHeight": 2.08,
                "tideHeightPercent": 0.72,
                "tideType": 1
            },
            {
                "dateTime": "2022-08-16T16:00:00Z",
                "wavesSizeMin": 6.211723733333333,
                "wavesSizeMax": 7.425634533333334,
                "primarySwellPeriod": 6,
                "primarySwellDirection": 118,
                "secondarySwellPeriod": 0,
                "secondarySwellDirection": 0,
                "longestSwellPeriod": 14,
                "longestSwellDirection": 219,
                "windDirection": 115.76575333333334,
                "windSpeed": 12.982771104416669,
                "tideHeight": 2.25,
                "tideHeightPercent": 0.77,
                "tideType": 1
            },
            {
                "dateTime": "2022-08-16T17:00:00Z",
                "wavesSizeMin": 6.091426266666667,
                "wavesSizeMax": 7.272528666666666,
                "primarySwellPeriod": 6,
                "primarySwellDirection": 118,
                "secondarySwellPeriod": 0,
                "secondarySwellDirection": 0,
                "longestSwellPeriod": 14,
                "longestSwellDirection": 148,
                "windDirection": 115.42105666666666,
                "windSpeed": 12.976136832743334,
                "tideHeight": 2.19,
                "tideHeightPercent": 0.75,
                "tideType": 1
            },
            {
                "dateTime": "2022-08-16T18:00:00Z",
                "wavesSizeMin": 5.9711288,
                "wavesSizeMax": 7.1194228,
                "primarySwellPeriod": 6,
                "primarySwellDirection": 118,
                "secondarySwellPeriod": 0,
                "secondarySwellDirection": 0,
                "longestSwellPeriod": 21,
                "longestSwellDirection": 222,
                "windDirection": 115.07636,
                "windSpeed": 12.969502561070001,
                "tideHeight": 1.93,
                "tideHeightPercent": 0.68,
                "tideType": 1
            },
            {
                "dateTime": "2022-08-16T19:00:00Z",
                "wavesSizeMin": 5.8070867999999995,
                "wavesSizeMax": 6.878827866666667,
                "primarySwellPeriod": 6,
                "primarySwellDirection": 119,
                "secondarySwellPeriod": 0,
                "secondarySwellDirection": 0,
                "longestSwellPeriod": 21,
                "longestSwellDirection": 222,
                "windDirection": 115.49252,
                "windSpeed": 12.809423309136667,
                "tideHeight": 1.54,
                "tideHeightPercent": 0.56,
                "tideType": 1
            },
            {
                "dateTime": "2022-08-16T20:00:00Z",
                "wavesSizeMin": 5.6430448,
                "wavesSizeMax": 6.638232933333333,
                "primarySwellPeriod": 6,
                "primarySwellDirection": 119,
                "secondarySwellPeriod": 0,
                "secondarySwellDirection": 0,
                "longestSwellPeriod": 21,
                "longestSwellDirection": 221,
                "windDirection": 115.90867999999999,
                "windSpeed": 12.649344057203335,
                "tideHeight": 1.14,
                "tideHeightPercent": 0.44,
                "tideType": 1
            },
            {
                "dateTime": "2022-08-16T21:00:00Z",
                "wavesSizeMin": 5.4790028,
                "wavesSizeMax": 6.397638,
                "primarySwellPeriod": 6,
                "primarySwellDirection": 120,
                "secondarySwellPeriod": 0,
                "secondarySwellDirection": 0,
                "longestSwellPeriod": 21,
                "longestSwellDirection": 221,
                "windDirection": 116.32484,
                "windSpeed": 12.48926480527,
                "tideHeight": 0.84,
                "tideHeightPercent": 0.36,
                "tideType": 1
            },
            {
                "dateTime": "2022-08-16T22:00:00Z",
                "wavesSizeMin": 5.391513733333333,
                "wavesSizeMax": 6.2992128,
                "primarySwellPeriod": 6,
                "primarySwellDirection": 120,
                "secondarySwellPeriod": 0,
                "secondarySwellDirection": 0,
                "longestSwellPeriod": 14,
                "longestSwellDirection": 147,
                "windDirection": 115.31101333333334,
                "windSpeed": 12.217468450036668,
                "tideHeight": 0.7,
                "tideHeightPercent": 0.32,
                "tideType": 1
            },
            {
                "dateTime": "2022-08-16T23:00:00Z",
                "wavesSizeMin": 5.304024666666667,
                "wavesSizeMax": 6.2007876,
                "primarySwellPeriod": 6,
                "primarySwellDirection": 121,
                "secondarySwellPeriod": 0,
                "secondarySwellDirection": 0,
                "longestSwellPeriod": 14,
                "longestSwellDirection": 220,
                "windDirection": 114.29718666666666,
                "windSpeed": 11.945672094803333,
                "tideHeight": 0.76,
                "tideHeightPercent": 0.33,
                "tideType": 1
            },
            {
                "dateTime": "2022-08-17T00:00:00Z",
                "wavesSizeMin": 5.2165356,
                "wavesSizeMax": 6.1023624000000005,
                "primarySwellPeriod": 6,
                "primarySwellDirection": 121,
                "secondarySwellPeriod": 0,
                "secondarySwellDirection": 0,
                "longestSwellPeriod": 14,
                "longestSwellDirection": 220,
                "windDirection": 113.28336,
                "windSpeed": 11.67387573957,
                "tideHeight": 1,
                "tideHeightPercent": 0.4,
                "tideType": 1
            },
            {
                "dateTime": "2022-08-17T01:00:00Z",
                "wavesSizeMin": 5.1509188,
                "wavesSizeMax": 6.036745600000001,
                "primarySwellPeriod": 6,
                "primarySwellDirection": 122,
                "secondarySwellPeriod": 0,
                "secondarySwellDirection": 0,
                "longestSwellPeriod": 14,
                "longestSwellDirection": 220,
                "windDirection": 115.31010333333333,
                "windSpeed": 12.02792554447,
                "tideHeight": 1.35,
                "tideHeightPercent": 0.51,
                "tideType": 1
            },
            {
                "dateTime": "2022-08-17T02:00:00Z",
                "wavesSizeMin": 5.0853019999999995,
                "wavesSizeMax": 5.9711288,
                "primarySwellPeriod": 6,
                "primarySwellDirection": 123,
                "secondarySwellPeriod": 0,
                "secondarySwellDirection": 0,
                "longestSwellPeriod": 14,
                "longestSwellDirection": 219,
                "windDirection": 117.33684666666667,
                "windSpeed": 12.38197534937,
                "tideHeight": 1.7,
                "tideHeightPercent": 0.61,
                "tideType": 1
            },
            {
                "dateTime": "2022-08-17T03:00:00Z",
                "wavesSizeMin": 5.0196852,
                "wavesSizeMax": 5.905512,
                "primarySwellPeriod": 6,
                "primarySwellDirection": 123,
                "secondarySwellPeriod": 0,
                "secondarySwellDirection": 0,
                "longestSwellPeriod": 20,
                "longestSwellDirection": 228,
                "windDirection": 119.36359,
                "windSpeed": 12.73602515427,
                "tideHeight": 1.97,
                "tideHeightPercent": 0.69,
                "tideType": 1
            },
            {
                "dateTime": "2022-08-17T04:00:00Z",
                "wavesSizeMin": 4.975940666666666,
                "wavesSizeMax": 5.8727036,
                "primarySwellPeriod": 6,
                "primarySwellDirection": 124,
                "secondarySwellPeriod": 0,
                "secondarySwellDirection": 0,
                "longestSwellPeriod": 20,
                "longestSwellDirection": 226,
                "windDirection": 124.58382333333334,
                "windSpeed": 12.840886603526666,
                "tideHeight": 2.07,
                "tideHeightPercent": 0.72,
                "tideType": 1
            },
            {
                "dateTime": "2022-08-17T05:00:00Z",
                "wavesSizeMin": 4.932196133333333,
                "wavesSizeMax": 5.8398952,
                "primarySwellPeriod": 6,
                "primarySwellDirection": 124,
                "secondarySwellPeriod": 0,
                "secondarySwellDirection": 0,
                "longestSwellPeriod": 20,
                "longestSwellDirection": 224,
                "windDirection": 129.80405666666667,
                "windSpeed": 12.945748052783335,
                "tideHeight": 1.96,
                "tideHeightPercent": 0.68,
                "tideType": 1
            },
            {
                "dateTime": "2022-08-17T06:00:00Z",
                "wavesSizeMin": 4.8884516,
                "wavesSizeMax": 5.8070868,
                "primarySwellPeriod": 6,
                "primarySwellDirection": 124,
                "secondarySwellPeriod": 0,
                "secondarySwellDirection": 0,
                "longestSwellPeriod": 20,
                "longestSwellDirection": 223,
                "windDirection": 135.02429,
                "windSpeed": 13.050609502040002,
                "tideHeight": 1.69,
                "tideHeightPercent": 0.61,
                "tideType": 1
            },
            {
                "dateTime": "2022-08-17T07:00:00Z",
                "wavesSizeMin": 4.866579333333333,
                "wavesSizeMax": 5.796150666666667,
                "primarySwellPeriod": 6,
                "primarySwellDirection": 125,
                "secondarySwellPeriod": 0,
                "secondarySwellDirection": 0,
                "longestSwellPeriod": 19,
                "longestSwellDirection": 223,
                "windDirection": 135.10335,
                "windSpeed": 13.44083102637,
                "tideHeight": 1.29,
                "tideHeightPercent": 0.49,
                "tideType": 1
            },
            {
                "dateTime": "2022-08-17T08:00:00Z",
                "wavesSizeMin": 4.8447070666666665,
                "wavesSizeMax": 5.785214533333334,
                "primarySwellPeriod": 6,
                "primarySwellDirection": 125,
                "secondarySwellPeriod": 0,
                "secondarySwellDirection": 0,
                "longestSwellPeriod": 19,
                "longestSwellDirection": 224,
                "windDirection": 135.18241,
                "windSpeed": 13.8310525507,
                "tideHeight": 0.89,
                "tideHeightPercent": 0.37,
                "tideType": 1
            },
            {
                "dateTime": "2022-08-17T09:00:00Z",
                "wavesSizeMin": 4.8228348,
                "wavesSizeMax": 5.7742784,
                "primarySwellPeriod": 6,
                "primarySwellDirection": 125,
                "secondarySwellPeriod": 0,
                "secondarySwellDirection": 0,
                "longestSwellPeriod": 19,
                "longestSwellDirection": 225,
                "windDirection": 135.26147,
                "windSpeed": 14.22127407503,
                "tideHeight": 0.57,
                "tideHeightPercent": 0.28,
                "tideType": 1
            },
            {
                "dateTime": "2022-08-17T10:00:00Z",
                "wavesSizeMin": 4.8228348,
                "wavesSizeMax": 5.763342266666666,
                "primarySwellPeriod": 6,
                "primarySwellDirection": 125,
                "secondarySwellPeriod": 0,
                "secondarySwellDirection": 0,
                "longestSwellPeriod": 19,
                "longestSwellDirection": 225,
                "windDirection": 132.98329666666666,
                "windSpeed": 13.96175454212,
                "tideHeight": 0.41,
                "tideHeightPercent": 0.23,
                "tideType": 1
            },
            {
                "dateTime": "2022-08-17T11:00:00Z",
                "wavesSizeMin": 4.8228348,
                "wavesSizeMax": 5.752406133333333,
                "primarySwellPeriod": 6,
                "primarySwellDirection": 125,
                "secondarySwellPeriod": 0,
                "secondarySwellDirection": 0,
                "longestSwellPeriod": 19,
                "longestSwellDirection": 225,
                "windDirection": 130.70512333333335,
                "windSpeed": 13.702235009210002,
                "tideHeight": 0.45,
                "tideHeightPercent": 0.24,
                "tideType": 1
            },
            {
                "dateTime": "2022-08-17T12:00:00Z",
                "wavesSizeMin": 4.8228348,
                "wavesSizeMax": 5.74147,
                "primarySwellPeriod": 6,
                "primarySwellDirection": 125,
                "secondarySwellPeriod": 0,
                "secondarySwellDirection": 0,
                "longestSwellPeriod": 19,
                "longestSwellDirection": 225,
                "windDirection": 128.42695,
                "windSpeed": 13.442715476300002,
                "tideHeight": 0.69,
                "tideHeightPercent": 0.31,
                "tideType": 1
            },
            {
                "dateTime": "2022-08-17T13:00:00Z",
                "wavesSizeMin": 4.8447070666666665,
                "wavesSizeMax": 5.719597733333333,
                "primarySwellPeriod": 6,
                "primarySwellDirection": 125,
                "secondarySwellPeriod": 0,
                "secondarySwellDirection": 0,
                "longestSwellPeriod": 13,
                "longestSwellDirection": 219,
                "windDirection": 128.38387666666668,
                "windSpeed": 13.170424160483336,
                "tideHeight": 1.06,
                "tideHeightPercent": 0.42,
                "tideType": 1
            },
            {
                "dateTime": "2022-08-17T14:00:00Z",
                "wavesSizeMin": 4.866579333333333,
                "wavesSizeMax": 5.697725466666666,
                "primarySwellPeriod": 6,
                "primarySwellDirection": 125,
                "secondarySwellPeriod": 0,
                "secondarySwellDirection": 0,
                "longestSwellPeriod": 13,
                "longestSwellDirection": 219,
                "windDirection": 128.34080333333333,
                "windSpeed": 12.898132844666668,
                "tideHeight": 1.47,
                "tideHeightPercent": 0.54,
                "tideType": 1
            },
            {
                "dateTime": "2022-08-17T15:00:00Z",
                "wavesSizeMin": 4.8884516,
                "wavesSizeMax": 5.6758532,
                "primarySwellPeriod": 6,
                "primarySwellDirection": 125,
                "secondarySwellPeriod": 0,
                "secondarySwellDirection": 0,
                "longestSwellPeriod": 13,
                "longestSwellDirection": 219,
                "windDirection": 128.29773,
                "windSpeed": 12.625841528850001,
                "tideHeight": 1.84,
                "tideHeightPercent": 0.65,
                "tideType": 1
            }
        ],
        "sunriseTimes": [
            "2022-08-14T22:30:50Z",
            "2022-08-15T22:30:27Z",
            "2022-08-16T22:30:03Z",
            "2022-08-17T22:29:39Z",
            "0001-01-01T00:00:00"
        ],
        "sunsetTimes": [
            "0001-01-01T00:00:00",
            "2022-08-15T10:20:08Z",
            "2022-08-16T10:20:08Z",
            "2022-08-17T10:20:09Z",
            "2022-08-18T10:20:09Z"
        ]
    }
}