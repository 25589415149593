{
    "timestamp": "2022-08-08T04:08:09.3910837Z",
    "forecast": {
        "hourlyForecasts": [
            {
                "dateTime": "2022-08-05T16:00:00Z",
                "weatherDescriptionId": 801,
                "rainMm": 0,
                "temperature": 25.02000000000004,
                "cloudCoverage": 20,
                "description": 3
            },
            {
                "dateTime": "2022-08-05T17:00:00Z",
                "weatherDescriptionId": 801,
                "rainMm": 0,
                "temperature": 25.02000000000004,
                "cloudCoverage": 20,
                "description": 3
            },
            {
                "dateTime": "2022-08-05T18:00:00Z",
                "weatherDescriptionId": 801,
                "rainMm": 0,
                "temperature": 23.910000000000025,
                "cloudCoverage": 20,
                "description": 3
            },
            {
                "dateTime": "2022-08-05T19:00:00Z",
                "weatherDescriptionId": 802,
                "rainMm": 0,
                "temperature": 23.910000000000025,
                "cloudCoverage": 40,
                "description": 6
            },
            {
                "dateTime": "2022-08-05T20:00:00Z",
                "weatherDescriptionId": 801,
                "rainMm": 0,
                "temperature": 25.02000000000004,
                "cloudCoverage": 20,
                "description": 3
            },
            {
                "dateTime": "2022-08-05T21:00:00Z",
                "weatherDescriptionId": 801,
                "rainMm": 0,
                "temperature": 23.910000000000025,
                "cloudCoverage": 20,
                "description": 3
            },
            {
                "dateTime": "2022-08-05T22:00:00Z",
                "weatherDescriptionId": 801,
                "rainMm": 0,
                "temperature": 23.910000000000025,
                "cloudCoverage": 20,
                "description": 3
            },
            {
                "dateTime": "2022-08-05T23:00:00Z",
                "weatherDescriptionId": 701,
                "rainMm": 0,
                "temperature": 22.80000000000001,
                "cloudCoverage": 20,
                "description": 4
            },
            {
                "dateTime": "2022-08-06T00:00:00Z",
                "weatherDescriptionId": 801,
                "rainMm": 0,
                "temperature": 23.910000000000025,
                "cloudCoverage": 20,
                "description": 3
            },
            {
                "dateTime": "2022-08-06T01:00:00Z",
                "weatherDescriptionId": 802,
                "rainMm": 0,
                "temperature": 25.02000000000004,
                "cloudCoverage": 40,
                "description": 6
            },
            {
                "dateTime": "2022-08-06T02:00:00Z",
                "weatherDescriptionId": 803,
                "rainMm": 0,
                "temperature": 25.58000000000004,
                "cloudCoverage": 75,
                "description": 1
            },
            {
                "dateTime": "2022-08-06T03:00:00Z",
                "weatherDescriptionId": 803,
                "rainMm": 0,
                "temperature": 27.80000000000001,
                "cloudCoverage": 75,
                "description": 1
            },
            {
                "dateTime": "2022-08-06T04:00:00Z",
                "weatherDescriptionId": 803,
                "rainMm": 0,
                "temperature": 27.80000000000001,
                "cloudCoverage": 75,
                "description": 1
            },
            {
                "dateTime": "2022-08-06T05:00:00Z",
                "weatherDescriptionId": 802,
                "rainMm": 0,
                "temperature": 28.99000000000001,
                "cloudCoverage": 40,
                "description": 6
            },
            {
                "dateTime": "2022-08-06T06:00:00Z",
                "weatherDescriptionId": 801,
                "rainMm": 0,
                "temperature": 27.80000000000001,
                "cloudCoverage": 20,
                "description": 3
            },
            {
                "dateTime": "2022-08-06T07:00:00Z",
                "weatherDescriptionId": 801,
                "rainMm": 0,
                "temperature": 28.99000000000001,
                "cloudCoverage": 20,
                "description": 3
            },
            {
                "dateTime": "2022-08-06T08:00:00Z",
                "weatherDescriptionId": 801,
                "rainMm": 0,
                "temperature": 28.910000000000025,
                "cloudCoverage": 20,
                "description": 3
            },
            {
                "dateTime": "2022-08-06T09:00:00Z",
                "weatherDescriptionId": 801,
                "rainMm": 0,
                "temperature": 28.910000000000025,
                "cloudCoverage": 20,
                "description": 3
            },
            {
                "dateTime": "2022-08-06T10:00:00Z",
                "weatherDescriptionId": 802,
                "rainMm": 0,
                "temperature": 26.689999999999998,
                "cloudCoverage": 40,
                "description": 6
            },
            {
                "dateTime": "2022-08-06T11:00:00Z",
                "weatherDescriptionId": 802,
                "rainMm": 0,
                "temperature": 25.58000000000004,
                "cloudCoverage": 40,
                "description": 6
            },
            {
                "dateTime": "2022-08-06T12:00:00Z",
                "weatherDescriptionId": 802,
                "rainMm": 0,
                "temperature": 25.58000000000004,
                "cloudCoverage": 40,
                "description": 6
            },
            {
                "dateTime": "2022-08-06T13:00:00Z",
                "weatherDescriptionId": 802,
                "rainMm": 0,
                "temperature": 25.58000000000004,
                "cloudCoverage": 40,
                "description": 6
            },
            {
                "dateTime": "2022-08-06T14:00:00Z",
                "weatherDescriptionId": 803,
                "rainMm": 0,
                "temperature": 23.910000000000025,
                "cloudCoverage": 75,
                "description": 1
            },
            {
                "dateTime": "2022-08-06T15:00:00Z",
                "weatherDescriptionId": 803,
                "rainMm": 0,
                "temperature": 23.910000000000025,
                "cloudCoverage": 75,
                "description": 1
            },
            {
                "dateTime": "2022-08-06T16:00:00Z",
                "weatherDescriptionId": 803,
                "rainMm": 0,
                "temperature": 23.910000000000025,
                "cloudCoverage": 75,
                "description": 1
            },
            {
                "dateTime": "2022-08-06T17:00:00Z",
                "weatherDescriptionId": 803,
                "rainMm": 0,
                "temperature": 23.910000000000025,
                "cloudCoverage": 75,
                "description": 1
            },
            {
                "dateTime": "2022-08-06T18:00:00Z",
                "weatherDescriptionId": 803,
                "rainMm": 0,
                "temperature": 23.910000000000025,
                "cloudCoverage": 75,
                "description": 1
            },
            {
                "dateTime": "2022-08-06T19:00:00Z",
                "weatherDescriptionId": 500,
                "rainMm": 0,
                "temperature": 23.910000000000025,
                "cloudCoverage": 75,
                "description": 5
            },
            {
                "dateTime": "2022-08-06T20:00:00Z",
                "weatherDescriptionId": 803,
                "rainMm": 0,
                "temperature": 23.910000000000025,
                "cloudCoverage": 82,
                "description": 1
            },
            {
                "dateTime": "2022-08-06T21:00:00Z",
                "weatherDescriptionId": 803,
                "rainMm": 0,
                "temperature": 23.910000000000025,
                "cloudCoverage": 75,
                "description": 1
            },
            {
                "dateTime": "2022-08-06T22:00:00Z",
                "weatherDescriptionId": 803,
                "rainMm": 0,
                "temperature": 23.910000000000025,
                "cloudCoverage": 75,
                "description": 1
            },
            {
                "dateTime": "2022-08-06T23:00:00Z",
                "weatherDescriptionId": 803,
                "rainMm": 0,
                "temperature": 23.910000000000025,
                "cloudCoverage": 75,
                "description": 1
            },
            {
                "dateTime": "2022-08-07T00:00:00Z",
                "weatherDescriptionId": 803,
                "rainMm": 0,
                "temperature": 23.910000000000025,
                "cloudCoverage": 75,
                "description": 1
            },
            {
                "dateTime": "2022-08-07T01:00:00Z",
                "weatherDescriptionId": 803,
                "rainMm": 0,
                "temperature": 25.02000000000004,
                "cloudCoverage": 75,
                "description": 1
            },
            {
                "dateTime": "2022-08-07T02:00:00Z",
                "weatherDescriptionId": 802,
                "rainMm": 0,
                "temperature": 25.58000000000004,
                "cloudCoverage": 40,
                "description": 6
            },
            {
                "dateTime": "2022-08-07T03:00:00Z",
                "weatherDescriptionId": 802,
                "rainMm": 0,
                "temperature": 27.80000000000001,
                "cloudCoverage": 40,
                "description": 6
            },
            {
                "dateTime": "2022-08-07T04:00:00Z",
                "weatherDescriptionId": 802,
                "rainMm": 0,
                "temperature": 26.689999999999998,
                "cloudCoverage": 40,
                "description": 6
            },
            {
                "dateTime": "2022-08-07T05:00:00Z",
                "weatherDescriptionId": 803,
                "rainMm": 0,
                "temperature": 27.80000000000001,
                "cloudCoverage": 75,
                "description": 1
            },
            {
                "dateTime": "2022-08-07T06:00:00Z",
                "weatherDescriptionId": 803,
                "rainMm": 0,
                "temperature": 27.80000000000001,
                "cloudCoverage": 75,
                "description": 1
            },
            {
                "dateTime": "2022-08-07T07:00:00Z",
                "weatherDescriptionId": 803,
                "rainMm": 0,
                "temperature": 27.80000000000001,
                "cloudCoverage": 75,
                "description": 1
            },
            {
                "dateTime": "2022-08-07T08:00:00Z",
                "weatherDescriptionId": 802,
                "rainMm": 0,
                "temperature": 26.689999999999998,
                "cloudCoverage": 40,
                "description": 6
            },
            {
                "dateTime": "2022-08-07T09:00:00Z",
                "weatherDescriptionId": 802,
                "rainMm": 0,
                "temperature": 26.689999999999998,
                "cloudCoverage": 40,
                "description": 6
            },
            {
                "dateTime": "2022-08-07T10:00:00Z",
                "weatherDescriptionId": 802,
                "rainMm": 0,
                "temperature": 26.689999999999998,
                "cloudCoverage": 40,
                "description": 6
            },
            {
                "dateTime": "2022-08-07T11:00:00Z",
                "weatherDescriptionId": 803,
                "rainMm": 0,
                "temperature": 25.58000000000004,
                "cloudCoverage": 75,
                "description": 1
            },
            {
                "dateTime": "2022-08-07T12:00:00Z",
                "weatherDescriptionId": 803,
                "rainMm": 0,
                "temperature": 25.58000000000004,
                "cloudCoverage": 75,
                "description": 1
            },
            {
                "dateTime": "2022-08-07T13:00:00Z",
                "weatherDescriptionId": 803,
                "rainMm": 0,
                "temperature": 25.58000000000004,
                "cloudCoverage": 75,
                "description": 1
            },
            {
                "dateTime": "2022-08-07T14:00:00Z",
                "weatherDescriptionId": 803,
                "rainMm": 0,
                "temperature": 25.58000000000004,
                "cloudCoverage": 75,
                "description": 1
            },
            {
                "dateTime": "2022-08-07T15:00:00Z",
                "weatherDescriptionId": 802,
                "rainMm": 0,
                "temperature": 25.58000000000004,
                "cloudCoverage": 40,
                "description": 6
            },
            {
                "dateTime": "2022-08-07T16:00:00Z",
                "weatherDescriptionId": 803,
                "rainMm": 0,
                "temperature": 25.58000000000004,
                "cloudCoverage": 75,
                "description": 1
            },
            {
                "dateTime": "2022-08-07T17:00:00Z",
                "weatherDescriptionId": 803,
                "rainMm": 0,
                "temperature": 25.02000000000004,
                "cloudCoverage": 75,
                "description": 1
            },
            {
                "dateTime": "2022-08-07T18:00:00Z",
                "weatherDescriptionId": 803,
                "rainMm": 0,
                "temperature": 25.02000000000004,
                "cloudCoverage": 75,
                "description": 1
            },
            {
                "dateTime": "2022-08-07T19:00:00Z",
                "weatherDescriptionId": 803,
                "rainMm": 0,
                "temperature": 25.02000000000004,
                "cloudCoverage": 75,
                "description": 1
            },
            {
                "dateTime": "2022-08-07T20:00:00Z",
                "weatherDescriptionId": 803,
                "rainMm": 0,
                "temperature": 25.02000000000004,
                "cloudCoverage": 75,
                "description": 1
            },
            {
                "dateTime": "2022-08-07T21:00:00Z",
                "weatherDescriptionId": 802,
                "rainMm": 0,
                "temperature": 25.02000000000004,
                "cloudCoverage": 40,
                "description": 6
            },
            {
                "dateTime": "2022-08-07T22:00:00Z",
                "weatherDescriptionId": 802,
                "rainMm": 0,
                "temperature": 25.02000000000004,
                "cloudCoverage": 40,
                "description": 6
            },
            {
                "dateTime": "2022-08-07T23:00:00Z",
                "weatherDescriptionId": 801,
                "rainMm": 0,
                "temperature": 25.02000000000004,
                "cloudCoverage": 20,
                "description": 3
            },
            {
                "dateTime": "2022-08-08T00:00:00Z",
                "weatherDescriptionId": 802,
                "rainMm": 0,
                "temperature": 25.58000000000004,
                "cloudCoverage": 40,
                "description": 6
            },
            {
                "dateTime": "2022-08-08T01:00:00Z",
                "weatherDescriptionId": 802,
                "rainMm": 0,
                "temperature": 26.689999999999998,
                "cloudCoverage": 40,
                "description": 6
            },
            {
                "dateTime": "2022-08-08T02:00:00Z",
                "weatherDescriptionId": 801,
                "rainMm": 0,
                "temperature": 28.910000000000025,
                "cloudCoverage": 20,
                "description": 3
            },
            {
                "dateTime": "2022-08-08T03:00:00Z",
                "weatherDescriptionId": 802,
                "rainMm": 0,
                "temperature": 28.910000000000025,
                "cloudCoverage": 40,
                "description": 6
            },
            {
                "dateTime": "2022-08-08T04:00:00Z",
                "weatherDescriptionId": 802,
                "rainMm": 0,
                "temperature": 28.910000000000025,
                "cloudCoverage": 40,
                "description": 6
            },
            {
                "dateTime": "2022-08-08T05:00:00Z",
                "weatherDescriptionId": 802,
                "rainMm": 0,
                "temperature": 28.535000000000025,
                "cloudCoverage": 39,
                "description": 6
            },
            {
                "dateTime": "2022-08-08T06:00:00Z",
                "weatherDescriptionId": 802,
                "rainMm": 0,
                "temperature": 28.160000000000025,
                "cloudCoverage": 38,
                "description": 6
            },
            {
                "dateTime": "2022-08-08T07:00:00Z",
                "weatherDescriptionId": 802,
                "rainMm": 0,
                "temperature": 27.820000000000032,
                "cloudCoverage": 34,
                "description": 6
            },
            {
                "dateTime": "2022-08-08T08:00:00Z",
                "weatherDescriptionId": 802,
                "rainMm": 0,
                "temperature": 27.480000000000036,
                "cloudCoverage": 30,
                "description": 6
            },
            {
                "dateTime": "2022-08-08T09:00:00Z",
                "weatherDescriptionId": 802,
                "rainMm": 0,
                "temperature": 27.140000000000043,
                "cloudCoverage": 25,
                "description": 6
            },
            {
                "dateTime": "2022-08-08T10:00:00Z",
                "weatherDescriptionId": 802,
                "rainMm": 0,
                "temperature": 26.24333333333338,
                "cloudCoverage": 21,
                "description": 6
            },
            {
                "dateTime": "2022-08-08T11:00:00Z",
                "weatherDescriptionId": 802,
                "rainMm": 0,
                "temperature": 25.34666666666671,
                "cloudCoverage": 17,
                "description": 6
            },
            {
                "dateTime": "2022-08-08T12:00:00Z",
                "weatherDescriptionId": 801,
                "rainMm": 0,
                "temperature": 24.450000000000045,
                "cloudCoverage": 12,
                "description": 3
            },
            {
                "dateTime": "2022-08-08T13:00:00Z",
                "weatherDescriptionId": 801,
                "rainMm": 0,
                "temperature": 24.31333333333337,
                "cloudCoverage": 11,
                "description": 3
            },
            {
                "dateTime": "2022-08-08T14:00:00Z",
                "weatherDescriptionId": 801,
                "rainMm": 0,
                "temperature": 24.176666666666694,
                "cloudCoverage": 10,
                "description": 3
            },
            {
                "dateTime": "2022-08-08T15:00:00Z",
                "weatherDescriptionId": 800,
                "rainMm": 0,
                "temperature": 24.04000000000002,
                "cloudCoverage": 8,
                "description": 2
            },
            {
                "dateTime": "2022-08-08T16:00:00Z",
                "weatherDescriptionId": 800,
                "rainMm": 0,
                "temperature": 24.04666666666668,
                "cloudCoverage": 11,
                "description": 2
            },
            {
                "dateTime": "2022-08-08T17:00:00Z",
                "weatherDescriptionId": 800,
                "rainMm": 0,
                "temperature": 24.05333333333334,
                "cloudCoverage": 14,
                "description": 2
            },
            {
                "dateTime": "2022-08-08T18:00:00Z",
                "weatherDescriptionId": 801,
                "rainMm": 0,
                "temperature": 24.060000000000002,
                "cloudCoverage": 17,
                "description": 3
            },
            {
                "dateTime": "2022-08-08T19:00:00Z",
                "weatherDescriptionId": 801,
                "rainMm": 0.049999999999999996,
                "temperature": 24.080000000000002,
                "cloudCoverage": 30,
                "description": 3
            },
            {
                "dateTime": "2022-08-08T20:00:00Z",
                "weatherDescriptionId": 801,
                "rainMm": 0.09999999999999999,
                "temperature": 24.100000000000005,
                "cloudCoverage": 43,
                "description": 3
            },
            {
                "dateTime": "2022-08-08T21:00:00Z",
                "weatherDescriptionId": 500,
                "rainMm": 0.15,
                "temperature": 24.120000000000005,
                "cloudCoverage": 57,
                "description": 5
            },
            {
                "dateTime": "2022-08-08T22:00:00Z",
                "weatherDescriptionId": 500,
                "rainMm": 0.18,
                "temperature": 24.450000000000006,
                "cloudCoverage": 56,
                "description": 5
            },
            {
                "dateTime": "2022-08-08T23:00:00Z",
                "weatherDescriptionId": 500,
                "rainMm": 0.21,
                "temperature": 24.780000000000012,
                "cloudCoverage": 55,
                "description": 5
            },
            {
                "dateTime": "2022-08-09T00:00:00Z",
                "weatherDescriptionId": 500,
                "rainMm": 0.24,
                "temperature": 25.110000000000014,
                "cloudCoverage": 53,
                "description": 5
            },
            {
                "dateTime": "2022-08-09T01:00:00Z",
                "weatherDescriptionId": 500,
                "rainMm": 0.29,
                "temperature": 25.613333333333344,
                "cloudCoverage": 49,
                "description": 5
            },
            {
                "dateTime": "2022-08-09T02:00:00Z",
                "weatherDescriptionId": 500,
                "rainMm": 0.33999999999999997,
                "temperature": 26.116666666666674,
                "cloudCoverage": 45,
                "description": 5
            },
            {
                "dateTime": "2022-08-09T03:00:00Z",
                "weatherDescriptionId": 500,
                "rainMm": 0.39,
                "temperature": 26.620000000000005,
                "cloudCoverage": 41,
                "description": 5
            },
            {
                "dateTime": "2022-08-09T04:00:00Z",
                "weatherDescriptionId": 500,
                "rainMm": 0.32,
                "temperature": 26.646666666666686,
                "cloudCoverage": 41,
                "description": 5
            },
            {
                "dateTime": "2022-08-09T05:00:00Z",
                "weatherDescriptionId": 500,
                "rainMm": 0.25,
                "temperature": 26.673333333333364,
                "cloudCoverage": 41,
                "description": 5
            },
            {
                "dateTime": "2022-08-09T06:00:00Z",
                "weatherDescriptionId": 500,
                "rainMm": 0.18,
                "temperature": 26.700000000000045,
                "cloudCoverage": 42,
                "description": 5
            },
            {
                "dateTime": "2022-08-09T07:00:00Z",
                "weatherDescriptionId": 500,
                "rainMm": 0.12,
                "temperature": 26.55333333333338,
                "cloudCoverage": 55,
                "description": 5
            },
            {
                "dateTime": "2022-08-09T08:00:00Z",
                "weatherDescriptionId": 500,
                "rainMm": 0.06,
                "temperature": 26.406666666666712,
                "cloudCoverage": 68,
                "description": 5
            },
            {
                "dateTime": "2022-08-09T09:00:00Z",
                "weatherDescriptionId": 803,
                "rainMm": 0,
                "temperature": 26.260000000000048,
                "cloudCoverage": 82,
                "description": 1
            },
            {
                "dateTime": "2022-08-09T10:00:00Z",
                "weatherDescriptionId": 803,
                "rainMm": 0.07999999999999999,
                "temperature": 25.893333333333374,
                "cloudCoverage": 80,
                "description": 1
            },
            {
                "dateTime": "2022-08-09T11:00:00Z",
                "weatherDescriptionId": 803,
                "rainMm": 0.15999999999999998,
                "temperature": 25.5266666666667,
                "cloudCoverage": 78,
                "description": 1
            },
            {
                "dateTime": "2022-08-09T12:00:00Z",
                "weatherDescriptionId": 500,
                "rainMm": 0.24,
                "temperature": 25.160000000000025,
                "cloudCoverage": 76,
                "description": 5
            },
            {
                "dateTime": "2022-08-09T13:00:00Z",
                "weatherDescriptionId": 500,
                "rainMm": 0.33999999999999997,
                "temperature": 25.086666666666684,
                "cloudCoverage": 69,
                "description": 5
            },
            {
                "dateTime": "2022-08-09T14:00:00Z",
                "weatherDescriptionId": 500,
                "rainMm": 0.44,
                "temperature": 25.01333333333334,
                "cloudCoverage": 62,
                "description": 5
            },
            {
                "dateTime": "2022-08-09T15:00:00Z",
                "weatherDescriptionId": 500,
                "rainMm": 0.54,
                "temperature": 24.939999999999998,
                "cloudCoverage": 54,
                "description": 5
            },
            {
                "dateTime": "2022-08-09T16:00:00Z",
                "weatherDescriptionId": 500,
                "rainMm": 0.84,
                "temperature": 24.79666666666668,
                "cloudCoverage": 53,
                "description": 5
            },
            {
                "dateTime": "2022-08-09T17:00:00Z",
                "weatherDescriptionId": 500,
                "rainMm": 1.14,
                "temperature": 24.653333333333364,
                "cloudCoverage": 52,
                "description": 5
            },
            {
                "dateTime": "2022-08-09T18:00:00Z",
                "weatherDescriptionId": 500,
                "rainMm": 1.44,
                "temperature": 24.510000000000048,
                "cloudCoverage": 51,
                "description": 5
            },
            {
                "dateTime": "2022-08-09T19:00:00Z",
                "weatherDescriptionId": 500,
                "rainMm": 1.2733333333333332,
                "temperature": 24.443333333333367,
                "cloudCoverage": 39,
                "description": 5
            },
            {
                "dateTime": "2022-08-09T20:00:00Z",
                "weatherDescriptionId": 500,
                "rainMm": 1.1066666666666667,
                "temperature": 24.376666666666683,
                "cloudCoverage": 26,
                "description": 5
            },
            {
                "dateTime": "2022-08-09T21:00:00Z",
                "weatherDescriptionId": 500,
                "rainMm": 0.94,
                "temperature": 24.310000000000002,
                "cloudCoverage": 13,
                "description": 5
            },
            {
                "dateTime": "2022-08-09T22:00:00Z",
                "weatherDescriptionId": 500,
                "rainMm": 0.8,
                "temperature": 24.706666666666667,
                "cloudCoverage": 16,
                "description": 5
            },
            {
                "dateTime": "2022-08-09T23:00:00Z",
                "weatherDescriptionId": 500,
                "rainMm": 0.66,
                "temperature": 25.103333333333335,
                "cloudCoverage": 19,
                "description": 5
            },
            {
                "dateTime": "2022-08-10T00:00:00Z",
                "weatherDescriptionId": 500,
                "rainMm": 0.52,
                "temperature": 25.5,
                "cloudCoverage": 23,
                "description": 5
            },
            {
                "dateTime": "2022-08-10T01:00:00Z",
                "weatherDescriptionId": 500,
                "rainMm": 0.4666666666666667,
                "temperature": 26.063333333333333,
                "cloudCoverage": 45,
                "description": 5
            },
            {
                "dateTime": "2022-08-10T02:00:00Z",
                "weatherDescriptionId": 500,
                "rainMm": 0.41333333333333333,
                "temperature": 26.626666666666665,
                "cloudCoverage": 67,
                "description": 5
            },
            {
                "dateTime": "2022-08-10T03:00:00Z",
                "weatherDescriptionId": 500,
                "rainMm": 0.36,
                "temperature": 27.189999999999998,
                "cloudCoverage": 89,
                "description": 5
            },
            {
                "dateTime": "2022-08-10T04:00:00Z",
                "weatherDescriptionId": 500,
                "rainMm": 0.38666666666666666,
                "temperature": 27.176666666666677,
                "cloudCoverage": 82,
                "description": 5
            },
            {
                "dateTime": "2022-08-10T05:00:00Z",
                "weatherDescriptionId": 500,
                "rainMm": 0.41333333333333333,
                "temperature": 27.163333333333355,
                "cloudCoverage": 75,
                "description": 5
            },
            {
                "dateTime": "2022-08-10T06:00:00Z",
                "weatherDescriptionId": 500,
                "rainMm": 0.44,
                "temperature": 27.150000000000034,
                "cloudCoverage": 67,
                "description": 5
            },
            {
                "dateTime": "2022-08-10T07:00:00Z",
                "weatherDescriptionId": 500,
                "rainMm": 0.38333333333333336,
                "temperature": 26.960000000000036,
                "cloudCoverage": 65,
                "description": 5
            },
            {
                "dateTime": "2022-08-10T08:00:00Z",
                "weatherDescriptionId": 500,
                "rainMm": 0.32666666666666666,
                "temperature": 26.77000000000004,
                "cloudCoverage": 63,
                "description": 5
            },
            {
                "dateTime": "2022-08-10T09:00:00Z",
                "weatherDescriptionId": 500,
                "rainMm": 0.27,
                "temperature": 26.58000000000004,
                "cloudCoverage": 60,
                "description": 5
            },
            {
                "dateTime": "2022-08-10T10:00:00Z",
                "weatherDescriptionId": 500,
                "rainMm": 0.45999999999999996,
                "temperature": 26.160000000000043,
                "cloudCoverage": 64,
                "description": 5
            },
            {
                "dateTime": "2022-08-10T11:00:00Z",
                "weatherDescriptionId": 500,
                "rainMm": 0.6499999999999999,
                "temperature": 25.740000000000048,
                "cloudCoverage": 68,
                "description": 5
            },
            {
                "dateTime": "2022-08-10T12:00:00Z",
                "weatherDescriptionId": 500,
                "rainMm": 0.84,
                "temperature": 25.32000000000005,
                "cloudCoverage": 72,
                "description": 5
            },
            {
                "dateTime": "2022-08-10T13:00:00Z",
                "weatherDescriptionId": 500,
                "rainMm": 0.7166666666666667,
                "temperature": 25.233333333333366,
                "cloudCoverage": 69,
                "description": 5
            },
            {
                "dateTime": "2022-08-10T14:00:00Z",
                "weatherDescriptionId": 500,
                "rainMm": 0.5933333333333333,
                "temperature": 25.146666666666686,
                "cloudCoverage": 66,
                "description": 5
            },
            {
                "dateTime": "2022-08-10T15:00:00Z",
                "weatherDescriptionId": 500,
                "rainMm": 0.47,
                "temperature": 25.060000000000002,
                "cloudCoverage": 63,
                "description": 5
            },
            {
                "dateTime": "2022-08-10T16:00:00Z",
                "weatherDescriptionId": 500,
                "rainMm": 0.59,
                "temperature": 24.97666666666667,
                "cloudCoverage": 60,
                "description": 5
            },
            {
                "dateTime": "2022-08-10T17:00:00Z",
                "weatherDescriptionId": 500,
                "rainMm": 0.71,
                "temperature": 24.893333333333334,
                "cloudCoverage": 57,
                "description": 5
            },
            {
                "dateTime": "2022-08-10T18:00:00Z",
                "weatherDescriptionId": 500,
                "rainMm": 0.83,
                "temperature": 24.810000000000002,
                "cloudCoverage": 54,
                "description": 5
            },
            {
                "dateTime": "2022-08-10T19:00:00Z",
                "weatherDescriptionId": 500,
                "rainMm": 0.6799999999999999,
                "temperature": 24.923333333333346,
                "cloudCoverage": 63,
                "description": 5
            },
            {
                "dateTime": "2022-08-10T20:00:00Z",
                "weatherDescriptionId": 500,
                "rainMm": 0.53,
                "temperature": 25.03666666666669,
                "cloudCoverage": 73,
                "description": 5
            },
            {
                "dateTime": "2022-08-10T21:00:00Z",
                "weatherDescriptionId": 500,
                "rainMm": 0.38,
                "temperature": 25.150000000000034,
                "cloudCoverage": 83,
                "description": 5
            },
            {
                "dateTime": "2022-08-10T22:00:00Z",
                "weatherDescriptionId": 500,
                "rainMm": 0.4033333333333333,
                "temperature": 25.370000000000022,
                "cloudCoverage": 82,
                "description": 5
            },
            {
                "dateTime": "2022-08-10T23:00:00Z",
                "weatherDescriptionId": 500,
                "rainMm": 0.4266666666666667,
                "temperature": 25.590000000000014,
                "cloudCoverage": 81,
                "description": 5
            },
            {
                "dateTime": "2022-08-11T00:00:00Z",
                "weatherDescriptionId": 500,
                "rainMm": 0.45,
                "temperature": 25.810000000000002,
                "cloudCoverage": 79,
                "description": 5
            },
            {
                "dateTime": "2022-08-11T01:00:00Z",
                "weatherDescriptionId": 500,
                "rainMm": 0.43666666666666665,
                "temperature": 26.27333333333335,
                "cloudCoverage": 69,
                "description": 5
            },
            {
                "dateTime": "2022-08-11T02:00:00Z",
                "weatherDescriptionId": 500,
                "rainMm": 0.42333333333333334,
                "temperature": 26.736666666666697,
                "cloudCoverage": 59,
                "description": 5
            },
            {
                "dateTime": "2022-08-11T03:00:00Z",
                "weatherDescriptionId": 500,
                "rainMm": 0.41,
                "temperature": 27.200000000000045,
                "cloudCoverage": 49,
                "description": 5
            },
            {
                "dateTime": "2022-08-11T04:00:00Z",
                "weatherDescriptionId": 500,
                "rainMm": 0.3833333333333333,
                "temperature": 27.320000000000032,
                "cloudCoverage": 47,
                "description": 5
            },
            {
                "dateTime": "2022-08-11T05:00:00Z",
                "weatherDescriptionId": 500,
                "rainMm": 0.3566666666666667,
                "temperature": 27.440000000000015,
                "cloudCoverage": 45,
                "description": 5
            },
            {
                "dateTime": "2022-08-11T06:00:00Z",
                "weatherDescriptionId": 500,
                "rainMm": 0.33,
                "temperature": 27.560000000000002,
                "cloudCoverage": 43,
                "description": 5
            },
            {
                "dateTime": "2022-08-11T07:00:00Z",
                "weatherDescriptionId": 500,
                "rainMm": 0.3433333333333333,
                "temperature": 27.22000000000001,
                "cloudCoverage": 48,
                "description": 5
            },
            {
                "dateTime": "2022-08-11T08:00:00Z",
                "weatherDescriptionId": 500,
                "rainMm": 0.3566666666666667,
                "temperature": 26.880000000000013,
                "cloudCoverage": 54,
                "description": 5
            },
            {
                "dateTime": "2022-08-11T09:00:00Z",
                "weatherDescriptionId": 500,
                "rainMm": 0.37,
                "temperature": 26.54000000000002,
                "cloudCoverage": 60,
                "description": 5
            },
            {
                "dateTime": "2022-08-11T10:00:00Z",
                "weatherDescriptionId": 500,
                "rainMm": 0.34,
                "temperature": 26.183333333333355,
                "cloudCoverage": 58,
                "description": 5
            },
            {
                "dateTime": "2022-08-11T11:00:00Z",
                "weatherDescriptionId": 500,
                "rainMm": 0.31,
                "temperature": 25.826666666666693,
                "cloudCoverage": 55,
                "description": 5
            },
            {
                "dateTime": "2022-08-11T12:00:00Z",
                "weatherDescriptionId": 500,
                "rainMm": 0.28,
                "temperature": 25.470000000000027,
                "cloudCoverage": 52,
                "description": 5
            },
            {
                "dateTime": "2022-08-11T13:00:00Z",
                "weatherDescriptionId": 500,
                "rainMm": 0.2833333333333333,
                "temperature": 25.453333333333358,
                "cloudCoverage": 49,
                "description": 5
            },
            {
                "dateTime": "2022-08-11T14:00:00Z",
                "weatherDescriptionId": 500,
                "rainMm": 0.2866666666666667,
                "temperature": 25.436666666666685,
                "cloudCoverage": 45,
                "description": 5
            },
            {
                "dateTime": "2022-08-11T15:00:00Z",
                "weatherDescriptionId": 500,
                "rainMm": 0.29,
                "temperature": 25.420000000000016,
                "cloudCoverage": 41,
                "description": 5
            },
            {
                "dateTime": "2022-08-11T16:00:00Z",
                "weatherDescriptionId": 500,
                "rainMm": 0.22999999999999998,
                "temperature": 25.340000000000014,
                "cloudCoverage": 50,
                "description": 5
            },
            {
                "dateTime": "2022-08-11T17:00:00Z",
                "weatherDescriptionId": 500,
                "rainMm": 0.16999999999999998,
                "temperature": 25.26000000000001,
                "cloudCoverage": 59,
                "description": 5
            },
            {
                "dateTime": "2022-08-11T18:00:00Z",
                "weatherDescriptionId": 500,
                "rainMm": 0.11,
                "temperature": 25.180000000000007,
                "cloudCoverage": 69,
                "description": 5
            },
            {
                "dateTime": "2022-08-11T19:00:00Z",
                "weatherDescriptionId": 500,
                "rainMm": 0.15,
                "temperature": 25.21666666666668,
                "cloudCoverage": 66,
                "description": 5
            },
            {
                "dateTime": "2022-08-11T20:00:00Z",
                "weatherDescriptionId": 500,
                "rainMm": 0.19,
                "temperature": 25.253333333333348,
                "cloudCoverage": 63,
                "description": 5
            },
            {
                "dateTime": "2022-08-11T21:00:00Z",
                "weatherDescriptionId": 500,
                "rainMm": 0.23,
                "temperature": 25.29000000000002,
                "cloudCoverage": 60,
                "description": 5
            },
            {
                "dateTime": "2022-08-11T22:00:00Z",
                "weatherDescriptionId": 500,
                "rainMm": 0.27666666666666667,
                "temperature": 25.53333333333336,
                "cloudCoverage": 60,
                "description": 5
            },
            {
                "dateTime": "2022-08-11T23:00:00Z",
                "weatherDescriptionId": 500,
                "rainMm": 0.32333333333333336,
                "temperature": 25.7766666666667,
                "cloudCoverage": 61,
                "description": 5
            },
            {
                "dateTime": "2022-08-12T00:00:00Z",
                "weatherDescriptionId": 500,
                "rainMm": 0.37,
                "temperature": 26.02000000000004,
                "cloudCoverage": 62,
                "description": 5
            },
            {
                "dateTime": "2022-08-12T01:00:00Z",
                "weatherDescriptionId": 500,
                "rainMm": 0.2866666666666667,
                "temperature": 26.566666666666702,
                "cloudCoverage": 51,
                "description": 5
            },
            {
                "dateTime": "2022-08-12T02:00:00Z",
                "weatherDescriptionId": 500,
                "rainMm": 0.20333333333333334,
                "temperature": 27.11333333333336,
                "cloudCoverage": 40,
                "description": 5
            },
            {
                "dateTime": "2022-08-12T03:00:00Z",
                "weatherDescriptionId": 500,
                "rainMm": 0.12,
                "temperature": 27.660000000000025,
                "cloudCoverage": 28,
                "description": 5
            },
            {
                "dateTime": "2022-08-12T04:00:00Z",
                "weatherDescriptionId": 500,
                "rainMm": 0.17333333333333334,
                "temperature": 27.750000000000018,
                "cloudCoverage": 28,
                "description": 5
            },
            {
                "dateTime": "2022-08-12T05:00:00Z",
                "weatherDescriptionId": 500,
                "rainMm": 0.22666666666666668,
                "temperature": 27.840000000000014,
                "cloudCoverage": 28,
                "description": 5
            },
            {
                "dateTime": "2022-08-12T06:00:00Z",
                "weatherDescriptionId": 500,
                "rainMm": 0.28,
                "temperature": 27.930000000000007,
                "cloudCoverage": 29,
                "description": 5
            },
            {
                "dateTime": "2022-08-12T07:00:00Z",
                "weatherDescriptionId": 500,
                "rainMm": 0.29333333333333333,
                "temperature": 27.616666666666674,
                "cloudCoverage": 30,
                "description": 5
            },
            {
                "dateTime": "2022-08-12T08:00:00Z",
                "weatherDescriptionId": 500,
                "rainMm": 0.3066666666666667,
                "temperature": 27.30333333333334,
                "cloudCoverage": 31,
                "description": 5
            },
            {
                "dateTime": "2022-08-12T09:00:00Z",
                "weatherDescriptionId": 500,
                "rainMm": 0.32,
                "temperature": 26.99000000000001,
                "cloudCoverage": 33,
                "description": 5
            },
            {
                "dateTime": "2022-08-12T10:00:00Z",
                "weatherDescriptionId": 500,
                "rainMm": 0.32666666666666666,
                "temperature": 26.56666666666668,
                "cloudCoverage": 33,
                "description": 5
            },
            {
                "dateTime": "2022-08-12T11:00:00Z",
                "weatherDescriptionId": 500,
                "rainMm": 0.33333333333333337,
                "temperature": 26.143333333333356,
                "cloudCoverage": 33,
                "description": 5
            },
            {
                "dateTime": "2022-08-12T12:00:00Z",
                "weatherDescriptionId": 500,
                "rainMm": 0.34,
                "temperature": 25.720000000000027,
                "cloudCoverage": 34,
                "description": 5
            },
            {
                "dateTime": "2022-08-12T13:00:00Z",
                "weatherDescriptionId": 500,
                "rainMm": 0.32,
                "temperature": 25.693333333333367,
                "cloudCoverage": 35,
                "description": 5
            },
            {
                "dateTime": "2022-08-12T14:00:00Z",
                "weatherDescriptionId": 500,
                "rainMm": 0.30000000000000004,
                "temperature": 25.666666666666703,
                "cloudCoverage": 37,
                "description": 5
            },
            {
                "dateTime": "2022-08-12T15:00:00Z",
                "weatherDescriptionId": 500,
                "rainMm": 0.28,
                "temperature": 25.640000000000043,
                "cloudCoverage": 39,
                "description": 5
            },
            {
                "dateTime": "2022-08-12T16:00:00Z",
                "weatherDescriptionId": 500,
                "rainMm": 0.1866666666666667,
                "temperature": 25.53000000000003,
                "cloudCoverage": 46,
                "description": 5
            },
            {
                "dateTime": "2022-08-12T17:00:00Z",
                "weatherDescriptionId": 500,
                "rainMm": 0.09333333333333335,
                "temperature": 25.420000000000016,
                "cloudCoverage": 53,
                "description": 5
            },
            {
                "dateTime": "2022-08-12T18:00:00Z",
                "weatherDescriptionId": 803,
                "rainMm": 0,
                "temperature": 25.310000000000002,
                "cloudCoverage": 61,
                "description": 1
            },
            {
                "dateTime": "2022-08-12T19:00:00Z",
                "weatherDescriptionId": 803,
                "rainMm": 0,
                "temperature": 25.21333333333335,
                "cloudCoverage": 61,
                "description": 1
            },
            {
                "dateTime": "2022-08-12T20:00:00Z",
                "weatherDescriptionId": 803,
                "rainMm": 0,
                "temperature": 25.116666666666692,
                "cloudCoverage": 60,
                "description": 1
            },
            {
                "dateTime": "2022-08-12T21:00:00Z",
                "weatherDescriptionId": 803,
                "rainMm": 0,
                "temperature": 25.02000000000004,
                "cloudCoverage": 59,
                "description": 1
            },
            {
                "dateTime": "2022-08-12T22:00:00Z",
                "weatherDescriptionId": 803,
                "rainMm": 0.09333333333333334,
                "temperature": 25.343333333333362,
                "cloudCoverage": 62,
                "description": 1
            },
            {
                "dateTime": "2022-08-12T23:00:00Z",
                "weatherDescriptionId": 803,
                "rainMm": 0.18666666666666668,
                "temperature": 25.666666666666686,
                "cloudCoverage": 65,
                "description": 1
            },
            {
                "dateTime": "2022-08-13T00:00:00Z",
                "weatherDescriptionId": 500,
                "rainMm": 0.28,
                "temperature": 25.99000000000001,
                "cloudCoverage": 69,
                "description": 5
            },
            {
                "dateTime": "2022-08-13T01:00:00Z",
                "weatherDescriptionId": 500,
                "rainMm": 0.36333333333333334,
                "temperature": 26.660000000000007,
                "cloudCoverage": 68,
                "description": 5
            },
            {
                "dateTime": "2022-08-13T02:00:00Z",
                "weatherDescriptionId": 500,
                "rainMm": 0.44666666666666666,
                "temperature": 27.330000000000002,
                "cloudCoverage": 67,
                "description": 5
            },
            {
                "dateTime": "2022-08-13T03:00:00Z",
                "weatherDescriptionId": 500,
                "rainMm": 0.53,
                "temperature": 28,
                "cloudCoverage": 66,
                "description": 5
            }
        ],
        "spotId": "Bali_Pererenan"
    }
}